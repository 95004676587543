/* route transition styles */

.slide-fade-enter #quizQuestion {
  opacity: 0.01;
  transform: translate3d(100%, 0, 0);
}

.slide-fade-enter.slide-fade-enter-active #quizQuestion {
  transition: all 300ms ease-in;
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.slide-fade-exit #quizQuestion {
  transform: translate3d(-100%, 0, 0);
  opacity: 0.01;
  transition: all 300ms ease-in;
}

.slide-fade-exit.slide-fade-exit-active #quizQuestion {
  transform: translate3d(-100%, 0, 0);
  opacity: 0.01;
  transition: all 300ms ease-in;
}
